./*!
	* Marina Adventure - APP Stylesheet (c) 2022 <bahaasamir.me>
*/

* {-webkit-font-smoothing: subpixel-antialiased; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;font-kerning:auto;}.html{-webkit-text-size-adjust:none;-webkit-font-smoothing:subpixel-antialiased;-webkit-font-smoothing:antialiased}i{font-style:normal;}a,body,div,footer,h1,h2,h3,h4,h5,h6,header,html,img,li,nav,p,span,textarea,ul{margin:0;padding:0;border:0;outline: 0;}li,ul{list-style:none;}a{color:inherit;text-decoration:none;}a,img,picture,svg,video{display:block;}svg{width:100%;max-width: 100%;height: auto;}html{-ms-text-size-adjust:100%;-webkit-overflow-scrolling: touch;-webkit-tap-highlight-color:transparent;}*, :after, :before {-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
html.has-scroll-smooth{overflow:hidden}html.has-scroll-dragging{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.has-scroll-smooth body{overflow:hidden}.has-scroll-smooth [data-scroll-container]{min-height:100vh}[data-scroll-direction=horizontal] [data-scroll-container]{display:inline-block;height:100vh;}[data-scroll-direction=horizontal] [data-scroll-section]{display:inline-block;height:100%;vertical-align:top;}[data-scroll-direction=horizontal] .c-scrollbar{visibility:hidden;}.c-scrollbar{height:100%;opacity:0;position:absolute;right:0;top:0;transform-origin:center right;transition:transform .3s,opacity .3s;width:11px}.c-scrollbar:hover{transform:scaleX(1.45)}.c-scrollbar:hover,.has-scroll-dragging .c-scrollbar,.has-scroll-scrolling .c-scrollbar{opacity:1}[data-scroll-direction=horizontal] .c-scrollbar{bottom:0;height:10px;top:auto;transform:scaleY(1);width:100%}[data-scroll-direction=horizontal] .c-scrollbar:hover{transform:scaleY(1.3)}.c-scrollbar_thumb{background-color:#000;border-radius:10px;cursor:-webkit-grab;cursor:grab;margin:2px;opacity:.5;position:absolute;right:0;top:0;width:7px}.has-scroll-dragging .c-scrollbar_thumb{cursor:-webkit-grabbing;cursor:grabbing}[data-scroll-direction=horizontal] .c-scrollbar_thumb{bottom:0;right:auto}

/* Assists */
*[data-scroll],
[data-scroll-section] {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-perspective: 1000;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	will-change: transform;
}

/* Basic */
body,
html {
	width: 100%;
	height: 100%;
}

main {
	position: relative;
	overflow: hidden;
}

.zero-font > span {
	font-size: 0;
	position: absolute;
}

.full_bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.full_page {
	height: calc(var(--vh, 1vh) * 100);
}

.flex {
	display: flex;
}

.flex.space-between {
	justify-content: space-between;
}

.flex.align-items-center {
	align-items: center;
}

.flex.justify-center {
	justify-content: center;
}

.logo_set {
	border-top: 1px solid #231F20;
}

.logo {
	border-right: 1px solid #231F20;
	border-left: 1px solid #231F20;
	background: #ef5153;
}

.ct_slide {
	width: calc(var(--vh, 1vh) * 177.777);
	flex: calc(var(--vh, 1vh) * 177.777) 0 0;
	height: calc(var(--vh, 1vh) * 100);
	position: relative;
}

.ct_slide i {
	background-size: cover;
	background-position: center;
}

@media (max-width: 1000px){
	.ct_set {
		display: block;
	}
	.ct_slide {
		width: 100%;
		height: 56.25vw;
		position: relative;
	}
}

@media (max-width: 500px){
	.logo_set {
		width: 100%;
		margin: 0 20px;
	}
}







